













/**
 * 公众号/小程序授权后展示结果页面
 */
import {Vue, Component} from "vue-property-decorator";

@Component
export default class AuthorizationRedirect extends Vue {
    close() {
        document.addEventListener('WeixinJSBridgeReady', function(){ WeixinJSBridge.call('closeWindow'); }, false);
        WeixinJSBridge.call('closeWindow');
    }
}

